declare global {
  interface Date {

    isToday(): boolean;
  }
}

/**
 * @example
 *   new Date('1989-11-09').isToday() // false;
 *   new Date().isToday() // true;
 */
if (!Date.prototype.isToday) {
  Date.prototype.isToday = function () {
    return this.toISOString().slice(0, 10) === new Date().toISOString().slice(0, 10)
  };
}


export {};
